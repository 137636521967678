<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSelection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      hiddenPage
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onRefresh="onRefresh"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'rate'"
              >{{ (scope.row.rate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'signedRate'"
              >{{ (scope.row.signedRate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'count'"
              >{{ scope.row.count }} / {{ scope.row.total }} 个</span
            >

            <span v-else-if="column.prop === 'signedCount'"
              >{{ scope.row.signedCount }} 个</span
            >
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <div>
          <span style="padding-right: 10px"
            >已签约合计数：{{ signedCountTotal }}</span
          >
          <span style="padding-right: 10px"
            >平均转化率：{{ (averageSignedRate * 100).toFixed(3) }} %</span
          >
          
        </div>
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientsStatisticsSources } from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import FilterSelection from "./components/FilterSelection";
import PageHeader from "@/components/PageHeader";
import { ReadLocalColumn } from "@/utils/table";
import config from "@/config";
export default {
  components: {
    BasicTable,
    FilterSelection,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  computed: {
    signedCountTotal() {
       if(this.tableData <= 0){
        return 0
      }
      let signedCountTotal = 0;
      this.tableData.forEach((item) => {
        signedCountTotal += item.signedCount;
      });
       return signedCountTotal
    },
    averageSignedRate(){
      if (this.tableData <= 0) {
        return 0;
      }
      let total = this.tableData[0].total;
      return this.signedCountTotal / total;
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "sourceName",
            label: "客源名称",
            showColumn: true,
            minWidth: "120px",
            sortable: false,
          },

          {
            prop: "count",
            label: "数量/总数",
            showColumn: true,
            minWidth: "120px",
            align: "left",
            sortable: false,
          },
          {
            prop: "rate",
            label: "占比",
            showColumn: true,
            minWidth: "120px",
            align: "left",
            sortable: false,
          },
          {
            prop: "signedCount",
            label: "已签约数量",
            showColumn: true,
            minWidth: "120px",
            sortable: false,
          },
          
          {
            prop: "signedRate",
            label: "转化率(已签约数量/数量)",
            showColumn: true,
            minWidth: "120px",
            sortable: false,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetClientsStatisticsSources({
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          this.tableData = res.data;
          this.total = res.data.length;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      this.orderDirection = order;
      if (order === null) {
        this.orderProp = "";
      } else {
        this.orderProp = prop;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>