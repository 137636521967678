import { apiv1 } from "@/request/apiv1";
import qs from "qs";

export function GetClientsStatisticsSources(params={}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/statistics/sources?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}